import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";

export default function Cursor() {
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const curs = useRef(null);
  const svg = useRef(null);

  useEffect(() => {
    // Check screen width and activate custom cursor only on large screens
    const isLargeScreen = window.innerWidth >= 768;

    if (!isLargeScreen) return; // Exit early if on small screens

    const images = document.querySelectorAll(".img");
    const tl = gsap.timeline({ paused: true });

    // Animation to grow the cursor and show the SVG
    tl.to(curs.current, { height: "60px", width: "60px", ease: "expo.inOut" })
      .to(svg.current, { opacity: 1, width: "100px", height: "100px" }, 0);

    images.forEach((img) => {
      img.addEventListener("mouseenter", () => {
        tl.play();
      });

      img.addEventListener("mouseleave", () => {
        tl.reverse();
        tl.eventCallback("onReverseComplete", () => {
          gsap.set(svg.current, { opacity: 0 }); // Hide the SVG
          gsap.set(curs.current, { opacity: 1, height: "10px", width: "10px" }); // Reset cursor size
        });
      });
    });

    function moveCursor(e) {
      setCursor({ x: e.clientX, y: e.clientY });
    }

    document.addEventListener("mousemove", moveCursor);

    return () => {
      document.removeEventListener("mousemove", moveCursor);
      images.forEach((img) => {
        img.removeEventListener("mouseenter", () => tl.play());
        img.removeEventListener("mouseleave", () => tl.reverse());
      });
    };
  }, []);

  const { x, y } = cursor;

  return (
    <div
      ref={curs}
      className="cursor pointer-events-none fixed left-1/2 top-1/2 z-[999] bg-[#fff201] h-[10px] w-[10px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full sm:flex"
      style={{ left: `${x}px`, top: `${y}px` }}
    >
      <svg
        ref={svg}
        xmlns="http://www.w3.org/2000/svg"
        className="scale-50 opacity-1"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6 19L19 6m0 0v12.48M19 6H6.52"
        />
      </svg>
    </div>
  );
}
